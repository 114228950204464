.rounded {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background: #4389db;
  margin: 0.5rem;
}

.rounded:first-child {
  background-color: #cb3636;
  margin-left: 0;
}

.rounded:last-child {
  background-color: #57d936;
}
