@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.rounded {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background: #4389db;
  margin: 0.5rem;
}

.rounded:first-child {
  background-color: #cb3636;
  margin-left: 0;
}

.rounded:last-child {
  background-color: #57d936;
}

html {
  background-color: #fcfcfc;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fill-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 60vw;
}

@media (min-width: 576px) {
  .wrapper {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .wrapper {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .wrapper {
    width: 1000px;
  }
}

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

